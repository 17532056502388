@import '~react-perfect-scrollbar/dist/css/styles.css';
@import './prism.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

:not(pre) > code {
  font-family: 'Kanit-Regular';
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}
@font-face {
  font-family: 'Kanit-Regular';
  src: local('Kanit-Regular'), url(../font/Kanit-Regular.ttf) format('woff');
}
@font-face {
  font-family: 'Kanit-Medium';
  src: local('Kanit-Regular'), url(../font/Kanit-Medium.ttf) format('woff');
}
@font-face {
  font-family: 'Kanit-SemiBold';
  src: local('Kanit-Regular'), url(../font/Kanit-SemiBold.ttf) format('woff');
}
/* // @font-face {
//   font-family: 'Kanit-Regular';
//   src: local('Kanit-Regular'), url(../font/Kanit-Regular.ttf) format('woff');
// }
// @font-face {
//   font-family: 'Kanit-Regular';
//   src: local('Kanit-Regular'), url(../font/Kanit-Regular.ttf) format('woff');
// }
// @font-face {
//   font-family: 'Kanit-Regular';
//   src: local('Kanit-Regular'), url(../font/Kanit-Regular.ttf) format('woff');
// }
// @font-face {
//   font-family: 'Kanit-Regular';
//   src: local('Kanit-Regular'), url(../font/Kanit-Regular.ttf) format('woff');
// }
// @font-face {
//   font-family: 'Kanit-Regular';
//   src: local('Kanit-Regular'), url(../font/Kanit-Regular.ttf) format('woff');
// }
// @font-face {
//   font-family: 'Kanit-Regular';
//   src: local('Kanit-Regular'), url(../font/Kanit-Regular.ttf) format('woff');
// } */

body {
  font-family: 'Kanit-Regular';
}