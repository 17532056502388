
@font-face {
    font-family: 'robotoblack_italic';
    src: url('assets/font/roboto-blackitalic-webfont.woff2') format('woff2'),
         url('assets/font/roboto-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kanitlight';
    src: url('assets/font/kanit-light-webfont.woff2') format('woff2'),
         url('assets/font/kanit-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}